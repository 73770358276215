














































































































import { Component, Vue } from 'vue-property-decorator'
import CardWithTitle from '@/components/Card/CardWithTitle.vue'
import FButton from '@/components/Button/FButton.vue'
import DownloadFileButton from '@/components/Button/DownloadFileButton.vue'
import HelpToggle from '@/components/HelpToggle/HelpToggle.vue';
import DownloadContents from './DownloadContents.vue';
import { TableColumn } from '@/models/TableColumn';
import { BusyService } from '@/services/BusyService';
import { ProfileService } from '@/services/ProfileService';
import { DownloadableItem } from '@/models/DownloadableItem';
import { ApiService } from '@/services/ApiService';
import { DownloadService } from '@/services/DownloadService';
import { ExpandableDownloadableItem } from '@/models/frontendOnly/ExpandableDownloadableItem';
import { bus, i18n } from '@/main';
import { DynamicTranslationService } from '@/services/DynamicTranslationService';

@Component({
  components: {
    CardWithTitle,
    FButton,
    HelpToggle,
    DownloadContents, 
    DownloadFileButton
  },
  metaInfo: () => ({
    title: DynamicTranslationService.downloadsTitle
  })
})
export default class Downloads extends Vue {
  downloadService = DownloadService;
  
  get columns(): TableColumn[] { 
    return [
      { key: 'date', type: 'date', label: i18n.t('G.LIT_Date').toString() },
      { key: 'user', label: i18n.t('G.LIT_User').toString() },
      { key: 'title', label: i18n.t('R.LIT_ItemName').toString()},
      { key: 'size', label: i18n.t('R.LIT_FileSize').toString() },
      { key: 'download', label: i18n.t('R.LIT_Download').toString() },
    ];
  }
  
  data: ExpandableDownloadableItem[] | null= null

  async getAvailableDownloads() { 
    if (!ProfileService.profile){ 
      throw new Error('Profile not loaded');
    }

    BusyService.showBusy();
    const data = await ApiService.post('/api/GetDownloads', { 
      organizationId: ProfileService.profile.org.id
    }) as DownloadableItem[];

    this.data = data.map(r => Object.assign({ contents: null, contentsError: false, expanded: false}, r));
    BusyService.hideBusy();
  }

  async mounted() {
    this.getAvailableDownloads();
    bus.$on('newDownloadsAvailable', this.getAvailableDownloads)
  }

  beforeDestroy(){ 
    bus.$off('newDownloadsAvailable', this.getAvailableDownloads)
  }

  onToggle(item: ExpandableDownloadableItem) { 
    item.expanded = !item.expanded;
    this.downloadService.getZipFileContents(item);
  }

  onHelpToggle(item: ExpandableDownloadableItem, expanded: boolean){ 
    item.expanded = expanded;
    this.downloadService.getZipFileContents(item);
  }
}
